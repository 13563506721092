/* global Component, uuidv4 */
class imageCollectionSectionComponent extends Component {

    static name() {
        return "imageCollectionSectionComponent";
    }

    static componentName() {
        return "imageCollectionSectionComponent";
    }

    getProps() {
        return {
            items: {
                type: Array,
                required: true
            },
            section: {
                type: Object,
                required: true
            }
        };
    }


    data() {
        return {
            ColumnSize: null,
        };
    }

    updated() {
        return function () {
            this.ColumnSize = this.section.ColumnSize;
        };
    }

    getWatch() {
        return {};
    }

    getComputed() {
        return {
            columnClass() {
                return `row-cols-1 row-cols-sm-${this.ColumnSize}`;
            },
        };
    }

    getMethods() {
        return {
            getCategory:this.getCategory,
            getCategoryLink:this.getCategoryLink,
            filterByCategory: this.filterByCategory
        };
    }

    getCategory(catCode){
        return this.$store.getters.getCategoryDataByCode(catCode);
    }

    getCategoryLink(catCode){
        let cat = this.$store.getters.getCategoryDataByCode(catCode);
        if(cat)
            return  `/items?category=${encodeURI(catCode + '__' + cat.name)}`;
        return "";
    }

    filterByCategory(itemFields) {
        if (itemFields.ItemCode) {
            let cat = this.getCategory(itemFields.ItemCode);
            if (cat) {
                this.$store.commit('updateAppState', 'loading');
                let objectFilter = {};
                objectFilter.name = uuidv4();
                objectFilter.type = 'globalClassification';
                objectFilter.condition = `x.ClassificationPath['${cat.code}']!=undefined`;
                objectFilter.label = cat.code;
                objectFilter.filterField = "Classification";
                this.$store.dispatch('addGlobalFilter', objectFilter);
                this.$store.dispatch('updateCategory', cat);
                this.emitEvent("category-select");
                window.scrollTo(0, 0);
                this.$router.push('/items?category=' + encodeURI(`${cat.code}__${cat.name}`));
            }
        } else if (itemFields.ItemLink) {
            this.$store.commit('updateAppState', 'loading');
            this.$router.push(itemFields.ItemLink.replace("#",""));
        }
    }

    getTemplate() {
        return `<div class="container">
                    <h5 class="col-sm-12 section-title">{{section.Title}} <small>({{items.length}})</small></h5>
                    <div class="row" :class="columnClass">
                        <div v-if="section.SectionType=='categoryList'" class="col oo-image-container" v-for="item of items">
                            <div  class="card" @click.stop="filterByCategory(item.fields)">
                               <template v-if="item.fields.ItemPhotoUri">
                                  <img  :src="item.fields.ItemPhotoUri" alt="Category" class="item-image" >
                                  <div class="image-title">
                                      <span><b>{{getCategoryLink(item.fields.ItemCode).name}}</b></span>
                                  </div>
                               </template>
                               <template v-else>
                                  <svg class="bd-placeholder-img rounded float-left" width="100%" height="auto" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMidYMid slice" focusable="false" role="img" aria-label="Placeholder: 200x200"><title>Placeholder</title><rect width="100%" height="100%" fill="#868e96"></rect><text x="50%" y="50%" fill="#dee2e6" dy=".3em">{{getCategoryLink(item.fields.ItemCode).name}}</text></svg>
                              </template>
                            </div>
                        </div>
                        <div v-if="section.SectionType=='officesList'" class="col oo-image-container" v-for="item of items">
                            <div class="card">
                               <img  :src="item.fields.ItemPhotoUri" alt="" class="office-image">
                                  <div class="text-center py-3 py-md-4">
                                    <p class="text-uppercase highlighted">{{item.fields.ItemName ? (item.fields.ItemName).split('_')[0] : ''}}</p>
                                    <p class="secondary-text">{{item.fields.ItemName ? (item.fields.ItemName).split('_')[1] : ''}}</p>
                                  </div>
                            </div>
                        </div>
                    </div>
                </div>`;
    }
}

imageCollectionSectionComponent.registerComponent();
